define('mailsMailRightCollapsedPurchaseOrderView',[
  'module',
  'windows',
  'marionette',
  'underscore',
  'entities/orders',
  'template!mailsMailRightCollapsedPurchaseOrderView'
], function (
  Module,
  Windows,
  Marionette,
  _,
  Orders,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'purchaseOrder-view',

    ui: {
      card: '.purchaseOrderCard',
      unlinkButton: '.unlink-button'
    },

    events: {
      'click @ui.card': 'onClick',
      'click @ui.unlinkButton': 'onUnlinkButtonClick'
    },

    serializeData: function () {
      if(this.model.get('purchaseOrderId')) {
        var quantity = this.model.get('quantity');
        var price = this.model.get('price');
        var formattedPrice = (price !== null && !isNaN(parseFloat(price))) ? (parseFloat(price).toFixed(2) + ' €') : '';
        var imgUrl = this.model.get('snapshotsUids') + '?action=res&params=t';
        var statusCode = this.model.get('status');
        var statusObj;
        if(this.model.get('commandType') === 'offer') {
          statusObj = _.find(this.model.get('offerStatuses'), function (stat) {
            return stat.get('statusConst') === statusCode;
          });
        } else if(this.model.get('commandType') === 'order') {
          statusObj = _.find(this.model.get('orderStatuses'), function (stat) {
            return stat.get('statusConst') === statusCode;
          });
        }
        var statusLabel = statusObj && statusObj.get('status');

        //console.log('purchaseOrder-view -> serializeData', this.model.get('offerStatuses'), this.model.get('orderStatuses'), statusLabel);

        return {
          quantity: (quantity ? (quantity + ' pcs') : ''),
          price: formattedPrice,
          statusCode: statusCode,
          statusLabel: statusLabel,
          imgUrl: imgUrl,
          editMode: this.model.get('editMode') === true
        };
      } else {
        return {
          poUrl: '',
          quantity: '',
          price: '',
          imgUrl: ''
        };
      }
    },

    onClick: function (e) {
      e.preventDefault();
      e.stopPropagation();

      if (this.model.get('purchaseOrderId')) {
        Orders.showOrder(this.model.get('purchaseOrderId'), '');
      }
    },

    onUnlinkButtonClick: function (e) {
      // Do not allow the event to bubble up to the parent view, it might trigger an Order popup to open
      e.preventDefault();
      e.stopPropagation();

      this.trigger('unlink'); //bubble event to parent view
    }

  });
});
  
