define('mailsMailRightCollapsedPurchaseOrdersView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'marionette',
  'mailsMailRightCollapsedPurchaseOrderView',
  'template!mailsMailRightCollapsedPurchaseOrdersView'
], function (
  Module,
  App,
  Backbone,
  _,
  Marionette,
  MailPurchaseOrderView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'purchaseOrders-view',
    childView: MailPurchaseOrderView,
    childViewContainer: '.purchaseOrders-container',

    modelEvents: {
      'change': 'fetch'
    },

    childEvents: {
      'unlink': function(childView) {
        this.collection.remove(childView.model);
        this.trigger('unlink'); //bubble event to parent view
      }
    },


    initialize: function() {
        this.collection = new Backbone.Collection();
        this.fetch();
        this.readTimer = null;
    },

    fetch: function() {
      this.collection.reset();
      //console.log('purchaseOrders fetch', this.options.offerStatuses, this.options.orderStatuses);

      if(this.model.get('purchaseOrders')) {
        _.each(this.model.get('purchaseOrders'), function (po) {
          po.offerStatuses = this.options.offerStatuses;
          po.orderStatuses = this.options.orderStatuses;
          po.editMode = this.options.editMode;
          this.collection.add(new Backbone.Model(po));
        }, this);
      }
    }

  });
});
