define('mailsMailMailTextView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'dateUtils',
  'moment',
  'template!mailsMailMailTextView',
  'entities/mails'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  DateUtils,
  moment,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'mail-text-view',

    ui: {
      mailIframe: '.mail-iframe'
    },

    onRender: function () {
      //console.log('mailtext onRender', this.model.get('content'));
    },

    onShow: function () {
      //console.log('mailtext onShow', this.model.get('content'));
      if(this.model.get('content') && this.ui.mailIframe && this.ui.mailIframe.length > 0) {
        var content = this.model.get('content');

        _.each(this.model.get('attachments'), function (attachment) {
          if (attachment.inline === true) {
            if (attachment.contentId) {
              var contentId = 'cid:' + attachment.contentId.slice(1, -1);
              var replacement;
              if (attachment.base64) {
                replacement = 'data:' + attachment.contentType + ';base64,' + attachment.base64;
              } else {
                var params = {
                  folder: encodeURIComponent(this.model.get('folder')?this.model.get('folder').fullName:'')
                };
                replacement = Settings.url('compuzz2', 'mails/fetch/' +
                    this.model.get('emailAddress').secId + '/' +
                    this.model.get('id') +
                    '/download/' + encodeURIComponent(attachment.fileName), params);
              }
              var regex = new RegExp(contentId, 'g');
              content = content.replace(regex, replacement.replace(/"/g, '\''));
            }
          }
        }, this);

        try {
          var iframeDoc = this.ui.mailIframe.get(0).contentWindow.document;
          iframeDoc.open();
          iframeDoc.write(content);
          iframeDoc.close();
        } catch (e) {
          // ignore
        }
      }
    }

  });
});

